import 'animate.css/animate.min.css'

import * as moment from 'moment'

import { Col, Grid, Sec, Wrap } from '../components/styled/layout'
import { Heading, Title } from '../components/styled/typography'
import React, { Component } from 'react'

import Img from 'gatsby-image'
import Layout from '../components/layout'
import META from '../components/META'
import ScrollAnimation from 'react-animate-on-scroll'
import StarIcon from '../assets/icons/baseline-star-24px.svg'
import { graphql } from 'gatsby'

const stickyStyles = {
  position: 'sticky',
  top: '40px',
}

const posterStyles = {
  borderRadius: '10px',
  overflow: 'hidden',
  boxShadow: '0px 15px 40px #00000030',
}

const ratingBoxStyles = {
  borderRadius: '5px',
  background: '#fbeaea',
  padding: '40px',
  display: 'block',
}

class FilmDetails extends Component {
  state = {
    mounted: false,
    details: null,
  }
  componentDidMount() {
    this.setState({
      mounted: !this.state.mounted,
    })
    if (localStorage.getItem(this.props.data.airtable.data.Imdb_ID)) {
      let state = this.state
      state.details = JSON.parse(
        localStorage.getItem(this.props.data.airtable.data.Imdb_ID)
      )
      this.setState({ ...state })
    } else {
      fetch(
        process.env.GATSBY_DOMAIN + '/.netlify/functions/fetchMovieDetails',
        {
          method: 'POST',
          body: this.props.data.airtable.data.Imdb_ID,
        }
      )
        .then(res => {
          return res.json()
        })
        .then(json => {
          localStorage.setItem(
            this.props.data.airtable.data.Imdb_ID,
            JSON.stringify(json)
          )
          let state = this.state
          state.details = json
          this.setState({ ...state })
        })
        .catch(err => console.log('error', err))
    }
  }
  render() {
    const movie = this.props.data.airtable.data

    const displayRating = rating => {
      const ratingArray = []
      for (let i = 0; i < rating; i++) {
        ratingArray.push(<StarIcon />)
      }
      return ratingArray.map((item, i) => <span key={i}>{item}</span>)
    }
    const renderGenres = () => {
      const { genres } = this.state.details
      const l = genres.length
      return genres.map((genre, index) => {
        if (index < l - 1) {
          return genre.name + ', '
        } else {
          return genre.name
        }
      })
    }
    const renderCast = () => {
      const { cast } = this.state.details.credits
      const l = cast.length
      const maxNum = 9
      const castArray = []
      cast.map((c, i) => {
        if (i < maxNum) {
          castArray.push(c.name)
        }
      })
      return castArray.join(', ') + ' och ' + (l - maxNum) + ' till...'
    }

    return (
      <>
        <Layout>
          <META title={movie.Film} />
          <Sec>
            <Wrap>
              <Grid cols="1fr 2fr">
                <Col>
                  <div style={stickyStyles}>
                    <ScrollAnimation
                      animateIn="fadeIn"
                      duration={1}
                      animateOnce={true}
                    >
                      <Img
                        style={posterStyles}
                        fluid={
                          movie.Filmbild.localFiles[0].childImageSharp.fluid
                        }
                      />
                    </ScrollAnimation>
                  </div>
                  <Grid />
                </Col>
                <Col>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    duration={1}
                    animateOnce={true}
                  >
                    <Title style={{ marginBottom: '0' }}>
                      <span>Recension</span>
                      {movie.Film}
                    </Title>
                    {this.state.details && (
                      <Col flex>
                        {' '}
                        <dl
                          style={{
                            marginTop: '30px',
                            marginRight: '25px',
                            fontSize: '0.8rem',
                            lineHeight: '1.2rem',
                          }}
                        >
                          <dt>Genré:</dt>
                          <dd>{renderGenres()}</dd>
                        </dl>
                        <dl
                          style={{
                            marginTop: '30px',
                            fontSize: '0.8rem',
                            lineHeight: '1.2rem',
                          }}
                        >
                          <dt>Släpptes:</dt>
                          <dd>
                            {moment(this.state.details.release_date).format(
                              'D MMMM, YYYY'
                            )}
                          </dd>
                        </dl>
                      </Col>
                    )}
                  </ScrollAnimation>

                  {this.state.details ? (
                    <ScrollAnimation
                      animateIn="fadeIn"
                      duration={1}
                      animateOnce={true}
                    >
                      <Sec space="0px 0px 40px 0px">
                        <Heading>Beskrivning</Heading>
                        <p>
                          {this.state.details.overview
                            ? this.state.details.overview
                            : 'Beskrivning av filmen saknas.'}
                        </p>
                        <strong>Skådespelare: </strong>
                        <p>
                          <small>{renderCast()}</small>
                        </p>
                      </Sec>
                    </ScrollAnimation>
                  ) : (
                    <Sec>Hämtar info om filmen...</Sec>
                  )}

                  {movie.Bloggtext && (
                    <ScrollAnimation
                      animateIn="fadeIn"
                      duration={1}
                      animateOnce={true}
                    >
                      <Sec style={{ borderTop: '1px solid #d8d8d8' }}>
                        <Col>
                          <Heading>
                            {movie.Datum} <span>{movie.Film}</span>
                          </Heading>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: movie.Bloggtext.childMarkdownRemark.html,
                            }}
                          />
                          {movie.Bilder && (
                            <ScrollAnimation
                              animateIn="fadeIn"
                              duration={1}
                              animateOnce={true}
                            >
                              <Sec>
                                <Grid
                                  gap="10px"
                                  cols={`repeat(${String(
                                    movie.Bilder.localFiles.length
                                  )} , 1fr)`}
                                >
                                  {movie.Bilder.localFiles.map((img, i) => (
                                    <Img
                                      fluid={img.childImageSharp.fluid}
                                      key={i}
                                    />
                                  ))}
                                </Grid>
                              </Sec>
                            </ScrollAnimation>
                          )}
                        </Col>
                      </Sec>
                    </ScrollAnimation>
                  )}
                  <Sec space="0">
                    <Heading>Recensioner</Heading>
                    <Grid cols="1fr">
                      <ScrollAnimation
                        animateIn="fadeIn"
                        duration={1}
                        animateOnce={true}
                      >
                        <Col style={ratingBoxStyles}>
                          <Heading>
                            <span>Recension:</span>Janet
                          </Heading>
                          <p>{movie.Janet_tyckte}</p>
                          <strong>
                            Betyg: <br />
                            {displayRating(movie.Janets_betyg)}
                          </strong>
                        </Col>
                      </ScrollAnimation>
                      <ScrollAnimation
                        animateIn="fadeIn"
                        duration={1}
                        animateOnce={true}
                      >
                        <Col style={ratingBoxStyles}>
                          <Heading>
                            <span>Recension:</span>
                            Lisa
                          </Heading>
                          <p>{movie.Lisa_tyckte}</p>
                          <strong>
                            Betyg: <br />
                            {displayRating(movie.Lisas_betyg)}
                          </strong>
                        </Col>
                      </ScrollAnimation>
                    </Grid>
                  </Sec>
                </Col>
              </Grid>
            </Wrap>
          </Sec>
        </Layout>
      </>
    )
  }
}

export default FilmDetails

export const pageQuery = graphql`
  query MovieByID($id: String!) {
    airtable(id: { eq: $id }) {
      data {
        Film
        Janet_tyckte
        Janets_betyg
        Lisa_tyckte
        Lisas_betyg
        Imdb_ID
        Datum(formatString: "D MMMM, YYYY", locale: "sv_SE")
        Bloggtext {
          childMarkdownRemark {
            html
          }
        }
        Bilder {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 700, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        Filmbild {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
